/* COLOR VARIABLES */
$background: white;
$color: black;
$border-color: #2B2B2B;

/* MEDIA QUERIES */
$phone: (max-width: 684px);
$tablet: (max-width: 900px);

/* variables for js, must be the same as these in @custom-media queries */
:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}
