.code-toolbar {
  $code-margin: 40px;
	position: relative;
	margin: $code-margin 0;
	padding: 20px;
	border: 1px solid rgba(0, 0, 0, .1);

	+ .code-toolbar,
	+ .highlight,
	+ .highlight .code-toolbar {
		border-top: 0;
		margin-top: calc(-1 * $code-margin);
	}

	pre, code {
		border: none;
	}
/*
	code {
		display: block;
		color: inherit;
	}*/

  > .toolbar {
    button {
      font-size: .8em !important;
      background: hsla(0,0%,87.8%,.2) !important;
      color: #4b4b4b !important;
      box-shadow: 0 2px 0 0 rgba(0,0,0,.2) !important;
      border-radius: 0 !important;
      margin: 6px !important;
      padding: 10px !important;
      user-select: none;
    }
  }
}
